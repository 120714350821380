<template>
  <div class="layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="mainCont" :class="{isFullScreen: isFullScreen}">
      <div class="game-content">
        <Star :totalStarNumber="optionList.length" :currentIndex="starNum" />
        <div class="title-area" v-if="titleInfo">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titleInfo.pinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleInfo.hanzi }}
            </div>
          </div>
        </div>
        <div class="background-img-area" :class="'lesson-' + lessonNum">
          <img
            class="bgIamge"
            :class="{
              isCover: backgroundSize == 'cover',
              fadeOut: isFadeOut,
              fadeIn: isFadeIn,
            }"
            :src="optionList[currentIndex].image"
          />
          <div
            class="speaker-wrapper"
            @click="playPinyinAudio"
            v-if="isHaveAudio"
          >
            <!-- <img :src="volumeImgSrc" alt="" class="speaker-img" /> -->
            <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
          </div>
        </div>
        <div class="bottonCont">
          <div class="redBackground"></div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in imgList"
              :key="index"
              class="content-words-two"
              :class="{
                bgcolor: !item.nobgcolor,
                isHidden: limitNumberOfVisibleOptions && !(
                  selectImageVisibility[item.id - 1] ||
                  item.id - 1 == currentIndex
                ),
              }"
              @click="foundAnswer(item.id)"
            >
              <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
              <img v-if="item.bottomImg" :src="item.bottomImg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Star from "../Star";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";

export default {
  components: {
    PageButton,
    Star,
    AudioPlayer,
  },
  props: {
    lessonNum: {
      type: Number,
      require: false,
    },
    optionList: {
      type: Array,
      require: true,
      default: () => [],
    },
    imgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    backgroundSize: {
      type: String,
      require: false,
      default: "",
    },
    isHaveAudio: {
      type: Boolean,
      default: false,
    },
    titleInfo: {
      type: Object,
      require: false,
    },
    limitNumberOfVisibleOptions: {
      type: Number,
      require: false
    },
    isFullScreen: {
      type: Boolean,
      require: false
    }
  },
  data() {
    return {
      steps: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },
      isLastStep: false,
      starNum: 0,
      currentIndex: 0,
      socketInfo: {},
      audioSocketInfo: {},
      isStartPlaying: null,
      songUrl: this.imgList[0].audioSrc,
      isFadeOut: false,
      isFadeIn: false,
      selectImageVisibility: [],
      randomNumber: 0,
    };
  },
  mounted() {
    this.$bus.$on("RecognitionPinyinClick", ({ index }) => {
      this.foundAnswer(index, true);
    });
    this.$bus.$on("RecognitionPinyinAudio", () => {
      this.playPinyinAudio("socket", true);
    });


    this.randomNumber = Math.floor(Math.random() * this.imgList.length);

    if (!this.limitNumberOfVisibleOptions) {
      //use shuffle only for specific games
      return;
    }
    // reset visible items (hide all)
    let myidx = 0;
    this.imgList.map((element) => {
      this.selectImageVisibility.push(0);
      myidx++;
    });
    this.selectImageVisibility[this.currentIndex] = 1;
    let itemsFound = 0;
    while (itemsFound < this.limitNumberOfVisibleOptions - 1) {
      this.randomNumber = Math.floor(Math.random() * this.imgList.length);
      if (
        this.randomNumber != this.currentIndex &&
        this.selectImageVisibility[this.randomNumber] != 1
      ) {
        console.log("⭐", this.randomNumber, this.currentIndex); // TO DO Debugging, To delete
        //if not current element (which we already show) and also not already in the list
        this.selectImageVisibility[this.randomNumber] = 1;
        itemsFound++;
      }
    }
  },
  beforeDestroy() {
    this.$bus.$off("RecognitionPinyinClick");
    this.$bus.$off("RecognitionPinyinAudio");
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20021,
          data: { value },
          text: "RecognitionPinyinGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    audioSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002101,
          data: { value },
          text: "RecognitionPinyinGame点击小喇叭 socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    shuffleSelectionImage() {
      if (!this.limitNumberOfVisibleOptions) {
        //use shuffle only for specific games
        return;
      }
      // reset visible items (hide all)
      let myidx = 0;
      this.imgList.map((element) => {
        this.selectImageVisibility[myidx] = 0;
        myidx++;
      });
      this.selectImageVisibility[this.currentIndex] = 1;
      let itemsFound = 0;
      while (itemsFound < this.limitNumberOfVisibleOptions - 1) {
        this.randomNumber = Math.floor(Math.random() * this.imgList.length);
        if (
          this.randomNumber != this.currentIndex &&
          this.selectImageVisibility[this.randomNumber] != 1
        ) {
          //if not current element (which we already show) and also not already in the list
          this.selectImageVisibility[this.randomNumber] = 1;
          itemsFound++;
        }
      }
    },
    playPinyinAudio(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.audioSocketInfo = {
          value: Math.random(),
        };
      }
      this.isStartPlaying = Math.random();
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    foundAnswer(index, isFromSocket = false) {
      if (this.starNum >= this.optionList.length) {
        return;
      }
      if (!isFromSocket) {
        this.socketInfo = {
          index,
          value: Math.random(),
        };
      }
      console.log(index);
      if (this.optionList[this.currentIndex].id === index) {
        playCorrectSound(true, false);
        this.starNum++;
        setTimeout(() => {
          if (this.currentIndex + 1 < this.optionList.length) {
            //first trigger the fadeout
            this.isFadeOut = true;
            setTimeout(() => {
              this.currentIndex++;
              this.shuffleSelectionImage();
              this.isFadeIn = true;
              this.isFadeOut = false;
            }, 500);
            setTimeout(() => {
              this.isFadeIn = false;
            }, 1000);
            this.songUrl = this.imgList[this.currentIndex + 1].audioSrc;
          } else {
            playCorrectSound();
            startConfetti();
            this.isLastStep = true;
          }
        }, 1000);
      } else {
        playCorrectSound(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bottonCont {
    bottom: 0;
    width: 80%;
    height: auto;
    position: absolute;
    display: flex;
    justify-content: center;
    margin-left: 11%;
    padding: 0 30px 90px 30px;
    width: 75%;
    box-sizing: border-box;

  .redBackground {
    width: 70%;
    height: 35%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #d87065;
    position: absolute;
    bottom: 0;

    @media screen and (max-width: 1300px) {
      width: 100%;
    }
  }
  .bottonsWrapper {
    display: flex;
    bottom: 10px;
    height: -moz-fit-content;
    justify-content: space-around;
    // height: 100%;
    padding: 20px;
    position: absolute;
    padding: 30px 30px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #ffffff;
    align-items: center;
    // width: 50%;
    width: auto;

    @media screen and (max-width: 1300px) {
      width: 70%;
    }

  }
}
.bgcolor {
  background-color: #ce524c;
  border-bottom: 10px solid #a53d32;
  border-top: 10px solid #d87065;
  padding: 10px 30px;
  box-sizing: border-box;
  // max-width: 150px;
}
.bgcolor:hover {
    background-color: #912016;
    border-bottom: 10px solid #d87065;
    border-top: 10px solid #a53d32;
}
.content-words-two {
  // max-width: 150px; 
  // width: 200px;
  min-width: 120px;
  white-space: nowrap;
  // min-height: 100px;
  padding: 20px 30px;

  z-index: 1;

  display: flex;
  flex-flow: column;
  justify-content: center;

  align-items: center;
  margin: 0 10px;

  border-radius: 40px;

  cursor: pointer;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
  img {
    max-width: 170px;
  }
}

.objectDisplay {
  position: absolute;
  height: 100%;
  //  bottom: 16%;
  //  left: 25%;
  width: 100%;
  z-index: 9999;
}
.soundIcon {
  position: absolute;
  width: 10%;
  top: 45%;
  left: 45%;
  cursor: pointer;
}
.speaker-wrapper {
  position: absolute;
  top: calc(55% - 35px);
  left: calc(50% - 35px);
  width: 70px;
  height: 70px;
  background-color: #cd4c3f;
  border-radius: 50%;
  text-align: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .speaker-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.toneImg {
  width: 20% !important;
  left: 40% !important;
  top: -3%;
}
.mainCont {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;

  &.isFullScreen {
    overflow: hidden;
    .background-img-area {
      max-height: none;
      max-width: 100%;
      height: auto;
    }

    .game-content {
      background-color: #f7f3e0;
    }
  }
}
.blueBg {
  background: #abeaff !important;
}
.game-content {
  text-align: -webkit-center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #f9f6e6;
  border-radius: 48px;


  .title-area {
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    top: 10%;
    width: 30%;
    height: 17%;
    background: #cd4c3f;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    color: #fff;
    .title {
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
}
.background-img-area {
  position: relative;
  width: -moz-fit-content;
  width: 100%;
  height: 100%;
  justify-content: center;
  z-index: 0;
  border-radius: 10%;
  display: flex;
  max-height: 80%;

  .bgIamge {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 48px;

    &.isCover {
      object-fit: cover !important;
    }
  }
}
.lesson-7 {
  .bgIamge {
    width: 60%;
    height: 60%;
    margin-top: 10%;
  }
}
.images {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  border-radius: 10%;
  img {
    position: relative;
    height: 100%;
    text-align: -webkit-center;
  }
}
</style>
